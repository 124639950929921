.listings {
    width: 100%;
    margin: 20px 0px;
    background-color: rgb(26, 38, 48, 0.5);
    border-radius: 10px;
    padding: 10px;
    /* color: rgba(255, 255, 255, 0.63); */
}

.listings .element:hover {
    background-color: rgb(26, 38, 48, 0.5);
    border-radius: 10px;
}

.listings .item {
    width: 100%;
    display: flex;
    align-items: center;
    height: fit-content;
    padding: 10px 0px;
}

.listings .item .icon {
    flex-basis: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: bold;
    display: grid;
    place-items: center;
    height: 50px;
    font-size: 20px;
    border-radius: 5px;
    background-color: rgba(8, 108, 190, 0.5);
}

.listings .item .name {
    margin: 0 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: medium;
    text-transform: capitalize;
}

.listings .item .name .sub {
    font-size: small;
    font-weight: normal;
    color: rgb(190, 188, 188);
}

.listings .item .name:hover {
    overflow: visible;
    white-space: normal;
}

.listings .item .value {
    font-weight: 600;
    white-space: nowrap;
    float: right;
}

.listings .item .value i {
    margin: 0 5px;
}

.listings .item .dropdown-btn {
    font-size: 17px;
    margin-left: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.63);
}

.listings .dropdown-btn:hover {
    color: #fff;
}

.listings .expanded {
    width: 100%;
    padding-bottom: 10px;
}

.listings .expanded .key-value-pairs {
    width: 100%;
    padding-bottom: 20px;
}

.listings .expanded .key-value-pairs .key {
    width: 40%;
    float: left;
    font-weight: 500;
}

.listings .expanded .key-value-pairs .value {
    width: 60%;
    float: right;
    text-align: right;
    font-weight: bold;
}

.listings .expanded button {
    width: 100%;
    margin-top: 10px;
    padding: 7px 0;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: small;
    background-color: rgb(249, 202, 38);
    border-radius: 10px;
    cursor: pointer;
}

.listings .expanded button:hover {
    background-color: rgb(194, 159, 34);
}

@media screen and (max-width: 600px) {
    .listings {
        padding: 0px;
        font-size: smaller;
    }

    .listings .item .dropdown-btn {
        margin-left: 10px;

    }
}

.empty-msg {
    width: 100%;
    padding: 100px 0;
    text-align: center;
}